import question from './question';
import questions from './questions';
import score from './score';
import scores from './scores';
import auth from './auth';

export const useQuestion = question;
export const useQuestions = questions;
export const useScore = score;
export const useScores = scores;
export const useAuthentication = auth;

import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Card, CardBody, CardHeader} from "shards-react";

class FieldSelector extends Component {
	state = {
		inactive: [],
		active: []
	};
	id2List = {
		droppable: 'inactive',
		droppable2: 'active'
	};

	componentWillMount() {
		const fields = this.props.fields.map(field => ({
			...field,
			id: `item-${field.name}`,
		}));
		this.setState({
			inactive: fields.filter(field => !field.active),
			active: fields.filter(field => field.active),
		});
	}

	reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	move = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const [removed] = sourceClone.splice(droppableSource.index, 1);

		destClone.splice(droppableDestination.index, 0, removed);

		const result = {};
		result[droppableSource.droppableId] = sourceClone;
		result[droppableDestination.droppableId] = destClone;

		return result;
	};

	getList = id => this.state[this.id2List[id]];

	onDragEnd = result => {
		const {source, destination} = result;

		if (!destination) {
			return;
		}
		let state = {};
		if (source.droppableId === destination.droppableId) {
			const items = this.reorder(
				this.getList(source.droppableId),
				source.index,
				destination.index
			);

			 state = source.droppableId === 'droppable2' ? {active: items} : {inactive: items};

		} else {
			const result = this.move(
				this.getList(source.droppableId),
				this.getList(destination.droppableId),
				source,
				destination
			);
			state = {
				inactive: result.droppable,
				active: result.droppable2
			};
		}
		this.setState(state);
		this.props.onChange([
			...(state.inactive || []).map(field => ({name: field.name, active: false})),
			...(state.active || []).map(field => ({name: field.name, active: true}))
		]);
	};

	render() {
		return (
			<div className="FieldSelector">
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Card small className="stats-small">
						<CardHeader className="border-bottom">
							<h6 className="p-0 m-0">Inactive fields</h6>
						</CardHeader>
						<CardBody className="p-0 d-flex">
							<Droppable droppableId="droppable">
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										className={`droparea ${snapshot.isDraggingOver ? 'over' : ''}`}>
										{this.state.inactive.map((item, index) => (
											<Draggable
												key={item.id}
												draggableId={item.id}
												index={index}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={`blog-comments__item d-flex p-2 draggable ${snapshot.isDragging ? 'dragging' : ''}`}>
														<div className="blog-comments__content">

															{/* Content :: Body */}
															<p className="m-0 my-1 mb-2 text-muted">
																{item.name}</p>
														</div>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</CardBody>
					</Card>
					<Card small className="stats-small">
						<CardHeader className="border-bottom">
							<h6 className="p-0 m-0">Active fields</h6>
						</CardHeader>
						<CardBody className="p-0 d-flex">
							<Droppable droppableId="droppable2">
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										className={`droparea ${snapshot.isDraggingOver ? 'over' : ''}`}>
										{this.state.active.map((item, index) => (
											<Draggable
												key={item.id}
												draggableId={item.id}
												index={index}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={`blog-comments__item d-flex p-2 draggable ${snapshot.isDragging ? 'dragging' : ''}`}>
														<div className="blog-comments__content">

															{/* Content :: Body */}
															<p className="m-0 my-1 mb-2 text-muted">
																{item.name}</p>
														</div>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</CardBody>
					</Card>
				</DragDropContext>
			</div>
		);
	}
}

export default FieldSelector;

import React, {Component} from "react";
import PropTypes from "prop-types";
import {Col, Container, Row} from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import {connect} from "react-redux";

class DefaultLayout extends Component {
	static propTypes = {
		noNavbar: PropTypes.bool,
		menuState: PropTypes.bool
	};

	static defaultProps = {
		noNavbar: false
	};

	render() {
		const {noNavbar, children, menuState} = this.props;
		return (
			<Container fluid>
				<Row>
					<MainSidebar/>
					<Col
						className="main-content p-0"
						lg={menuState ? {size: 10, offset: 2} : {size: 11, offset: 1}}
						md={menuState ? {size: 9, offset: 3} : {size: 11, offset: 1}}
						sm="12"
						tag="main"
					>
						{!noNavbar && <MainNavbar/>}
						{children}
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	menuState: state.layout.menuState
});

export default connect(
	mapStateToProps
)(DefaultLayout);

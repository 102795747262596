import React, {Fragment, useState} from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	FormInput,
	FormSelect,
	Row, Slider
} from "shards-react";
import {withRouter} from "react-router-dom";

import {PageTitle} from "./../components";
import {useScore} from "../hooks";
import {cloneDeep} from 'lodash';
import FileBase64 from 'react-file-base64';

const Score = (props) => {
	const [state, setState] = useState({scores:[], editScores: -1});
	const {score, checkValid, setScore, saveScore} = useScore(props.match.params.score || 0);
	const submit = async (e) => {
		e.preventDefault();
		if(!checkValid(score))
			return;
		await saveScore();
		props.history.push('/scores')
	}
	return (
		<Container fluid className="main-content-container px-4">
			<Row noGutters className="page-header py-4">
				<Col>
					<PageTitle title={score.scoreId ? 'Scorethema bewerken' : 'Scorethema toevoegen'} subtitle="beheren" />
				</Col>
			</Row>
			<Row>
				<Col>
					<Card small className="mb-4">
						<CardHeader className="border-bottom">
							<h6 className="m-0">Eigenschappen van het thema</h6>
						</CardHeader>
						<CardBody className="">
							<Form onSubmit={submit}>
								<FormGroup>
									<label htmlFor="fiTitle">Key</label>
									<FormInput id="fiTitle" type="text" className="form-control" placeholder="Unieke sleutel" required="required"
											   value={score.key} onChange={(e) => setScore({key: e.target.value})}/>
								</FormGroup>
								<FormGroup>
									<label htmlFor="fiText">Titel</label>
									<FormInput id="fiText" type="text" className="form-control" placeholder="Titel van het thema" required="required"
											   value={score.text} onChange={(e) => setScore({text: e.target.value})}/>
								</FormGroup>
								<FormGroup>
									<Button variant="primary" type="submit" onClick={submit}>Opslaan</Button>
								</FormGroup>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}
export default withRouter(Score);

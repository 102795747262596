import React, {Fragment, useState} from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	FormInput,
	FormSelect,
	Row, Slider
} from "shards-react";
import {withRouter} from "react-router-dom";

import {PageTitle} from "./../components";
import {useQuestion} from "../hooks";
import {cloneDeep} from 'lodash';
import FileBase64 from 'react-file-base64';

const Question = (props) => {
	const [state, setState] = useState({scores:[], editScores: -1});
	const {question, checkValid, setQuestion, saveQuestion} = useQuestion(props.match.params.question || 0);
	const submit = async (e) => {
		e.preventDefault();
		if(!checkValid(question))
			return;
		await saveQuestion();
		props.history.push('/questions')
	}
	const Answer = ({index, showAnswer}) => {
		return (
			<Fragment>
				<Col xs={12} md={6}>
					<FormGroup>
						{
							index === 0 ?
							<label htmlFor={`fiQuestion${index}`}>Knoptekst</label> : null
						}
						<Row>
							<Col xs={9}>
								<FormInput id={`fiQuestion${index}`} type="text" className="form-control" placeholder="Text van het antwoord" required="required"
										   value={question.answers[index].text} onChange={(e) => {
									const q = cloneDeep(question);
									q.answers[index].text = e.target.value;
									setQuestion(q);
								}}/>
							</Col>
							<Col xs={3}>
								{
									showAnswer ?
										<Button onClick={() => {
											const q = cloneDeep(question);
											q.answers[index].scores = cloneDeep(state.scores).map(s => ({
												score: s._id,
												value: s.value
											}));
											setQuestion(q);
											setState({scores: [], editScores: -1});
										}}>Scores opslaan</Button> :
										<Button onClick={() => {
											const scores = [...question.scores].map(s => ({...s, value: 0}));
											question.answers[index].scores.forEach(s => {
												scores.find(s2 => s.score === s2._id).value = s.value ;
											})
											console.log('scores', scores)
											setState({scores, editScores: index});
										}}>Wijzig scores</Button>
								}
							</Col>
						</Row>
					</FormGroup>
				</Col>
				{
					!showAnswer ?
						<Col xs={12} md={6}>
							{
								index === 0 ?
									<label htmlFor={`fiQuestion${index}`}>Scores</label> : null
							}
							<ul className="scores">
							{
								question.answers[index].scores.filter(score => score.value !== 0).map((score, idx) => (
									<li key={idx} className={score.value > 0 ? 'positive' : 'negative'}>
										{question.scores.find(sc => sc._id === score.score).text} <span className="value">{`${score.value > 0 ? '+' : ''}${score.value}`}</span>
									</li>
								))
							}
							</ul>
						</Col> : <Col xs={12} md={6}>
							<ul className="score-edit">
								{
									state.scores.map((score, idx) => {
										return (
											<li key={idx}>
												<div>
													{score.text}
												</div>
												<div>
													<Slider
														start={[score.value]}
														step={1}
														format={{to: num => Number(num).toFixed(0), from: num => Number(num)}}
														tooltips={true}
														range={{min: -3, max: 3}}
														style={{display: 'inline-block', width: '200px'}}
														onChange={(val) => {
															const scores = cloneDeep(state.scores);
															scores[idx].value = parseInt(val);
															setState({scores, editScores: state.editScores});
														}}
													/>
												</div>
											</li>
										)
									})
								}
							</ul>
						</Col>
				}
			</Fragment>
		)
	}
	return (
		<Container fluid className="main-content-container px-4">
			<Row noGutters className="page-header py-4">
				<Col>
					<PageTitle title={question.questionId ? 'Vraag bewerken' : 'Vraag toevoegen'} subtitle="beheren" />
				</Col>
			</Row>
			<Row>
				<Col>
					<Card small className="mb-4">
						<CardHeader className="border-bottom">
							<h6 className="m-0">Eigenschappen van de vraag</h6>
						</CardHeader>
						<CardBody className="">
							<Form onSubmit={submit}>
								<FormGroup>
									<label htmlFor="fiTitle">Titel</label>
									<FormInput id="fiTitle" type="text" className="form-control" placeholder="Titel van de vraag" required="required"
											   value={question.title} onChange={(e) => setQuestion({title: e.target.value})}/>
								</FormGroup>
								<FormGroup>
									<label htmlFor="fiText">Tekst</label>
									<FormInput id="fiText" type="text" className="form-control" placeholder="Text van de vraag" required="required"
											   value={question.text} onChange={(e) => setQuestion({text: e.target.value})}/>
								</FormGroup>
								<h6>Antwoorden</h6>
								<Row>
									<Answer index={0} showAnswer={state.editScores === 0}/>
									<Answer index={1} showAnswer={state.editScores === 1}/>
								</Row>
								<FormGroup>
									<label htmlFor="fiPhoto">Achtergrond foto</label>
									<Row>
										<Col xs={12} md={6}>
											<FileBase64
												id="fiPhoto"
												multiple={ false }
												onDone={ (file) => {
													setQuestion({photo: file.base64})
												} } />
										</Col>
										<Col xs={12} md={6}>
											{
												question.photo ?
													<img className="photo" src={question.photo} alt=""/> : null
											}
										</Col>
									</Row>
								</FormGroup>
								<FormGroup>
									<label htmlFor="fiYoutube">Filmpje</label>
									<FormInput id="fiYoutube" type="text" className="form-control" placeholder="Youtube adres" required="required"
											   value={question.video} onChange={(e) => setQuestion({video: e.target.value})}/>
								</FormGroup>
								<FormGroup>
									<Button variant="primary" type="submit" onClick={submit}>Opslaan</Button>
								</FormGroup>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}
export default withRouter(Question);

import {login} from './account';
import {getQuestion, saveQuestion, updateQuestion, listQuestions} from './question'
import {getScore, saveScore, updateScore, listScores} from "./score";

export const account = {
    login
};

export const question = {
	getQuestion,
	saveQuestion,
	updateQuestion,
	listQuestions
};

export const score = {
	getScore,
	saveScore,
	updateScore,
	listScores
};

import { createStore } from 'redux';
import rootReducer  from './reducers';
import {loadState, saveState} from './localstorage';
import {debounce} from 'lodash';

const persistedState = loadState();
const store = createStore(rootReducer, persistedState)
store.subscribe(debounce(() => {
    saveState(store.getState())
}, 1000));

const next = store.dispatch;
store.dispatch = (action) => {
    console.log('dispatching', action);
    let result = next(action);
    console.log('next state', store.getState());
    return result
};

export default store;

import React, {Component} from "react";
import {Col, Container, Row} from "shards-react";
import {withRouter} from "react-router-dom";

import PageTitle from "./../components/common/PageTitle";

class Dashboard extends Component {

	render() {
		return (
			<Container fluid className="main-content-container px-4">
				{/* Page Header */}
				<Row noGutters className="page-header py-4">
					<Col>
						<PageTitle title="Dashboard" subtitle="notores" />
					</Col>
				</Row>

			</Container>
		)
	}
}
export default withRouter(Dashboard);

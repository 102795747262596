import React, {Component} from "react";
import {Container, Row, Col, Card, CardBody} from "shards-react";
import {withRouter} from "react-router-dom";

import PageTitle from "./../components/common/PageTitle";

class ModuleList extends Component {

	state = {
		modules: []
	}
	componentWillMount = async () => {
		const req = await fetch('http://localhost:8080/n-admin/modules', {
			headers: {
				accept: 'application/json'
			}
		});
		const res = await req.json();
		this.setState({modules: res.modules.filter(mod => mod.name !== 'admin')})
	}

	navigateToModule = (module) => {
		this.props.history.push(`/modules/${encodeURIComponent(module)}/models`)
	}

	render() {
		const {modules} = this.state;
		return (
			<Container fluid className="main-content-container px-4">
				{/* Page Header */}
				<Row noGutters className="page-header py-4">
					<PageTitle title="Installed Modules" subtitle="Management" />
				</Row>

				{/* Small Stats Blocks */}
				<Row>
					{modules.map((module, idx) => {
						const moduleBits = module.name.split('/');
						const canManage = module.models.length >= 1
						return (
							<Col className=" mb-4" key={idx} sm={4} xs={6} lg={3}>
								<Card small className="stats-small" style={canManage ? {cursor: 'pointer'} : {}} onClick={() => this.navigateToModule(module.name)}>
									<CardBody className="p-0 d-flex">
										<div className="d-flex flex-column m-auto" style={{width: '100%'}}>
											<div className="stats-small__data text-center" style={{width: '100%', maxWidth: '100%', textAlign: 'center'}}>
												<span className="stats-small__label text-uppercase">{moduleBits.length > 1 ? moduleBits[0] : <span>&nbsp;</span>}</span>
												<h6 className="stats-small__value count my-3" style={!canManage ? {color: 'rgba(61, 81, 112, 0.5)'} : {}}>
													{moduleBits.length > 1 ? moduleBits[1] : module.name}
												</h6>
											</div>
											<div className="stats-small__data" style={{width: '100%', maxWidth: '100%', textAlign: 'center'}}>
												<span
													className="stats-small__percentage" style={{width: '100%', maxWidth: '100%', textAlign: 'center', paddingLeft: 0}}>
													{canManage ? 'Manage Data' :  'No management options'}
												</span>
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						)
					})}
				</Row>
			</Container>
		)
	}
}
export default withRouter(ModuleList);
//http://localhost:8080/n-admin/list-modules

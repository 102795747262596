import React, {Component, Fragment} from "react";
import {Button, Col, Container, Row} from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import PropTypes from "prop-types";
import FieldSelector from "../components/generic/FieldSelector";
import {moduleTables} from "../store/actions";
import {connect} from "react-redux";
import DataTable from "../components/generic/DataTable";

class ModelFields extends Component {

	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				module: PropTypes.string,
				model: PropTypes.string,
			})
		})
	};

	state = {
		model: {},
		showActiveFields: false,
	}
	componentWillMount = async () => {
		await this.loadModel();
		await this.loadData();

	}

	loadModel = async () => {
		const {module, model} = this.props.match.params;
		const req = await fetch(`http://localhost:8080/n-admin/modules/${module}/models/${model}`, {
			headers: {
				accept: 'application/json'
			}
		});
		const res = await req.json();
		const resModel = res.model;
		const actives = this.props.moduleTables[`${decodeURIComponent(module)}/${model}`] || [];
		console.log('ModelFields building', resModel, actives)
		resModel.fields = resModel.fields.map(field => ({
			...field,
			active: (actives.find(f => f.name === field.name) || {active: true}).active
		}));
		this.setState({model: resModel});
	}
	loadData = async () => {
		const {module, model} = this.props.match.params;
		const req = await fetch(`http://localhost:8080/n-admin/modules/${module}/models/${model}/data`, {
			headers: {
				accept: 'application/json'
			}
		});
		const res = await req.json();
		console.log('loadData', res.data)
		this.setState({data: res.data || []});
	}

	render() {
		const {model, showActiveFields, data} = this.state;
		const module = decodeURIComponent(this.props.match.params.module);
		return (
			<Container fluid className="main-content-container px-4">
				<Row noGutters className="page-header py-4">
					<PageTitle title="Fields for Module" subtitle={`${module}/${model.name}`}
							   className="text-sm-left mb-3"/>
				</Row>
				{model.fields ?
					<Fragment>
						<Row>
							<Col className="mb-4">
								<Button pill onClick={() => this.setState({showActiveFields: !showActiveFields})}>{
									showActiveFields ?
										<Fragment>&uarr;</Fragment> :
										<Fragment>&darr;</Fragment>
								} Manage shown fields</Button>
							</Col>
						</Row>
						{
							showActiveFields ?
								<Row>
									<Col className="mb-4">
										<FieldSelector fields={model.fields} onChange={(set) => {
											console.log('FielSelector OnChange', set);

											this.setState({
												model: {
													...model,
													fields: set
												}
											});
											this.props.dispatchers.updateModuleTable(
												`${module}/${model.name}`,
												set.map(field => ({
													name: field.name,
													active: field.active
												}))
											)
										}}/>
									</Col>
								</Row> : null
						}
						{
							data && data.length ?
								<Row>
									<Col>
										<DataTable columns={model.fields} data={data}/>
									</Col>
								</Row> : null
						}
					</Fragment> : null}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	moduleTables: state.moduleTables
});

const mapDispatchToProps = (dispatch) => ({
	dispatchers: {
		updateModuleTable: (model, fields) => dispatch(moduleTables.updateModuleTable(model, fields))
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModelFields);

import React from "react";
import { Redirect } from "react-router-dom";

// Route Views
import {Dashboard, ModuleList, ModelList, ModelFields, QuestionList, ScoreList, Question, Score} from "./views"

export default [
	{
		path: "/",
		exact: true,
		component: () => <Redirect to="/dashboard" />
	},
	{
		path: "/dashboard",
		component: Dashboard
	},
	{
		path: "/modules",
		component: ModuleList,
		exact: true
	},
	{
		path: "/modules/:module/models",
		component: ModelList,
		exact: true
	},
	{
		path: "/modules/:module/models/:model",
		component: ModelFields,
		exact: true
	},
	{
		path: "/questions",
		component: QuestionList,
		exact: true
	},
	{
		path: "/question/:question",
		component: Question,
		exact: true
	},
	{
		path: "/scores",
		component: ScoreList,
		exact: true
	},
	{
		path: "/score/:score",
		component: Score,
		exact: true
	}
];

import { useState, useEffect } from 'react';
import {store} from "../store";
import {question as questionApi, score} from "../api";
import {isEqual} from 'lodash';

const updateQuestion = (question) => {
    return async () => {
        return await questionApi.updateQuestion(question.questionId, question, question.token);
    }
}

const saveQuestion = (question) => {
    return async () => {
        const {token} = store.getState().user;
        return await questionApi.saveQuestion({...question}, token);
    }
}

const checkValid = (question) => {
    return question.title && question.text
};

function useQuestion(questionId) {
    const initialState = {
    	title: '',
    	text: '',
    	answers: [{scores:[{value: 0}]},{scores:[{value: 0}]}],
		video: '',
		photo: '',
        questionId: questionId || '',
		scores: [],
        token: ''
    }
    console.log(initialState)
    const [question, setQuestion] = useState(initialState);

    useEffect(() => {
        const {token} = store.getState().user;

		if(!question.token){
			if(question.questionId){
				(async () => {
						if(token) {
							const state = await questionApi.getQuestion(question.questionId, token);
							const state2 = await score.listScores(token);
							console.log(state)
							if(state.question){
								setQuestion({...question, ...state.question, scores: state2.scores, token});
							}
						}
					}
				)();
			}else{
				setQuestion({...question, token});
			}
		}
    }, [question]);
	console.log(question)
    return {
        question,
        setQuestion: (info) => {
            if(question){
                info = {...question, ...info};
                if(!isEqual(info,question) )
                    setQuestion(info)
            }else{
                setQuestion(info)
            }

        },
        saveQuestion: question._id ? updateQuestion(question) : saveQuestion(question),
        checkValid
    };
}

export default useQuestion;

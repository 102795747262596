import React from "react";
import {Nav} from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import navbarItems from "../../../data/sidebar-nav-items";
import PropTypes from "prop-types";

class SidebarNavItems extends React.Component {
	static propTypes = {
		hideLinkText: PropTypes.bool
	};

	static defaultProps = {
		hideLinkText: false
	};

	render() {
		const {hideLinkText} = this.props;
		return (
			<div className="nav-wrapper">
				<Nav className="nav--no-borders flex-column">
					{navbarItems().map((item, idx) => (
						<SidebarNavItem key={idx} item={item} hideLinkText={hideLinkText}/>
					))}
				</Nav>
			</div>
		)
	}
}

export default SidebarNavItems;

import { useState, useEffect } from 'react';
import {store} from "../store";
import {score as scoreApi} from "../api";
import {isEqual} from 'lodash';

const updateScore = (score) => {
    return async () => {
        return await scoreApi.updateScore(score.scoreId, score, score.token);
    }
}

const saveScore = (score) => {
    return async () => {
        const {token} = store.getState().user;
        return await scoreApi.saveScore({...score}, token);
    }
}

const checkValid = (score) => {
    return score.key && score.text
};

function useScore(scoreId) {
    const initialState = {
    	key: '',
    	text: '',
        scoreId: scoreId || '',
        token: ''
    }
    const [score, setScore] = useState(initialState);

    useEffect(() => {
        const {token} = store.getState().user;

		if(!score.token){
			if(score.scoreId){
				(async () => {
						if(token) {
							const state = await scoreApi.getScore(score.scoreId, token);
							if(state.score){
								setScore({...score, ...state.score, token});
							}
						}
					}
				)();
			}else{
				setScore({...score, token});
			}
		}
    }, [score]);
    return {
        score,
        setScore: (info) => {
            if(score){
                info = {...score, ...info};
                if(!isEqual(info,score) )
                    setScore(info)
            }else{
                setScore(info)
            }

        },
        saveScore: score._id ? updateScore(score) : saveScore(score),
        checkValid
    };
}

export default useScore;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {store} from './store'
import {Provider} from "react-redux";
import {http} from "./lib";
import config from './config/application';

http.init(config.api);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root'));

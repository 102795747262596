import {loginAction, logoutAction} from './user';
import {toggleMenuState} from './layout';
import {updateModuleTable} from './moduleTables';

export const user = {
    loginAction,
    logoutAction
}

export const layout = {
	toggleMenuState
}

export const moduleTables = {
	updateModuleTable
}

import types from '../types';

export const loginAction = (token) => {
    console.log('loginAction', token)
    return {
        type: types.user.LOGIN, payload: {
            token
        }
    }
}

export const logoutAction = () => {
    return {
        type: types.user.LOGOUT
    }
}

import {http} from '../lib';

const login = async (username, password) => {
    try {
        const result = await http.httpPost('/login', {
            email: username,
            password
        }, null,(err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
};


export {
    login
}

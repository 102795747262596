import { useState, useEffect } from 'react';
import {store} from "../store";
import {score} from "../api";

function useScores() {
    const [data, setData] = useState({scores: [], token: ''});

    useEffect(() => {
        if(!data.token){
            (async () => {
                    const {token} = store.getState().user;
                    if(token) {
                        const state = await score.listScores(token);
                        if(state.scores){
                            setData({scores: state.scores, token});
                        }
                    }
                }
            )();
        }
    });

    return {
		scores: data.scores
    };
}

export default useScores;

import storeInstance from './store';
import reducer from './reducers';
import {user, layout, moduleTables} from './actions';

export const store = storeInstance;
export const rootReducer = reducer;
export const actions = {
    user,
	layout,
	moduleTables
};

import React from "react";
import {layout} from "../../../store/actions";
import {connect} from "react-redux";

class NavbarToggle extends React.Component {
	render() {
		console.log('NavbarToggle', this.props)
		return (
			<nav className="nav">
				<span onClick={this.props.dispatchers.toggleMenuState}
				   className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-inline text-center">
					<i className="material-icons">&#xE5D2;</i>
				</span>
			</nav>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatchers: {
		toggleMenuState: () => dispatch(layout.toggleMenuState())
	}
});

export default connect(
	null,
	mapDispatchToProps
)(NavbarToggle);

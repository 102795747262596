import React from "react";
import {Col, Container, Row} from "shards-react";
import {withRouter, Link} from "react-router-dom";

import {PageTitle, TableCard} from "./../components";
import {useScores} from "../hooks";

const ScoreList = () => {
	const {scores} = useScores();
	return (
		<Container fluid className="main-content-container px-4">
			<Row noGutters className="page-header py-4">
				<Col>
					<PageTitle title="Score thema's" subtitle="overzicht" />
				</Col>
			</Row>
			<Row>
				<Col>
					<TableCard title="Huidige thema's" columns={[
						{ title: 'Key', dataIndex: 'key', key: 'key', width: 100 },
						{ title: 'Thema', dataIndex: 'text', key: 'text' },
						{
							title: 'Acties',
							dataIndex: '',
							key: 'actions',
							render(record) {
								return <Link to={`/score/${record._id}`}>Bekijk</Link>;
							},
							width: 200
						}
					]} data={scores || []}/>
				</Col>
			</Row>
		</Container>
	)
}
export default withRouter(ScoreList);

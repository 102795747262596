const types = {
    user: {
        LOGOUT: "LOGOUT",
        LOGIN: "LOGIN",
        REFRESH: "REFRESH"
    },
	layout: {
		TOGGLE_MENU_STATE: 'TOGGLE_MENU_STATE'
	},
	moduleTables: {
		UPDATE_MODULE_TABLE: 'UPDATE_MODULE_TABLE'
	}
};
export default types;

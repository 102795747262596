import React from 'react';
import {Card, CardBody, CardHeader} from "shards-react";
import Table from "rc-table";

export default ({title, columns, data}) => {
	const defaultCol = {
		onHeaderCell:	()=>({className:"border-0"})
	};
	return (
		<Card small className="mb-4">
			<CardHeader className="border-bottom">
				<h6 className="m-0">{title}</h6>
			</CardHeader>
			<CardBody className="p-0 pb-3">
				<Table className="table mb-0"
					   onHeaderRow={()=>({className:"bg-light"})}
					   columns={columns.map(col => ({...defaultCol, ...col}))}
					   data={data}
					   />
			</CardBody>
		</Card>
	);
}

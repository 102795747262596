export default {
    JWT: {
        allowedAudience: 'proj-nick',
        allowedIssuer: 'proj-nick'
    },
    api: {
        //uri: 'https://atob-server.staging.notores.com'
        //uri: 'http://localhost:8080',
		uri: 'https://server.proj-nick.isopgewekt.app'
    },
    google: {
        apiKey: 'AIzaSyANRoa_hgO7Y0dKmWObrplwFqqNpoQsrh0' //'AIzaSyDL7C322HLLBB_c2hQIXvBRlEExJWvNMVg'
    }
}

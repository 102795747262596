import React from "react";
import classNames from "classnames";
import {Col} from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";
import {connect} from "react-redux";

class MainSidebar extends React.Component {

	render() {
		const {menuState} = this.props;
		const classes = classNames(
			"main-sidebar",
			"px-0",
			"col-12",
			menuState && "open"
		);

		return (
			<Col
				tag="aside"
				className={classes}
				lg={{size: menuState ? 2 : 1}}
				md={{size: menuState ? 3 : 1}}
			>
				<SidebarMainNavbar hideLogoText={!menuState}/>
				<SidebarSearch/>
				<SidebarNavItems hideLinkText={!menuState}/>
			</Col>
		);
	}
}


const mapStateToProps = (state) => ({
	menuState: state.layout.menuState
});

export default connect(
	mapStateToProps
)(MainSidebar);

import types from '../types';
import { moduleTables as initial } from '../initial.json';

const moduleTables = (state = initial, action) => {
	switch (action.type) {
		case types.moduleTables.UPDATE_MODULE_TABLE:
			return {...state, [action.payload.model]: action.payload.fields};
		default:
			return state
	}
}

export default moduleTables

import {http} from '../lib';

const getQuestion = async (questionId, token) => {
    try {
        const result = await http.httpGet(`/questions/${questionId}`,token,(err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
};

const saveQuestion = async (data, token) => {
    try {
        const result = await http.httpPost(`/questions`,data, token,(err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
};

const updateQuestion = async (questionId, data, token) => {
    try {
        const result = await http.httpPatch(`/questions/${questionId}`,data, token,(err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
};

const listQuestions = async (token) => {
    try {
        const result = await http.httpGet(`/questions`, token, (err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
}

export {
	getQuestion,
	saveQuestion,
	updateQuestion,
	listQuestions
}

import React, {Component} from "react";
import {Container, Row, Col, Card, CardBody} from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import PropTypes from "prop-types";

class ModuleList extends Component {

	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				module: PropTypes.string
			})
		})
	};

	state = {
		models: []
	}
	componentWillMount = async () => {
		console.log('this.props', this.props)
		const {module} = this.props.match.params;
		const req = await fetch(`http://localhost:8080/n-admin/modules/${module}/models`, {
			headers: {
				accept: 'application/json'
			}
		});
		const res = await req.json();
		this.setState({models: res.models})
	}

	navigateToModel = (model) => {
		this.props.history.push(`/modules/${this.props.match.params.module}/models/${encodeURIComponent(model)}`)
	}

	render() {
		const {models} = this.state;
		const module = decodeURIComponent(this.props.match.params.module);
		return (
			<Container fluid className="main-content-container px-4">
				{/* Page Header */}
				<Row noGutters className="page-header py-4">
					<PageTitle title="Models for Module" subtitle={module} />
				</Row>

				{/* Small Stats Blocks */}
				<Row>
					{models.map((model, idx) => {
						return (
							<Col className=" mb-4" key={idx} sm={4} xs={6} lg={3}>
								<Card small className="stats-small" style={{cursor: 'pointer'}} onClick={() => this.navigateToModel(model.name)}>
									<CardBody className="p-0 d-flex">
										<div className="d-flex flex-column m-auto" style={{width: '100%'}}>
											<div className="stats-small__data text-center" style={{width: '100%', maxWidth: '100%', textAlign: 'center'}}>
												<span className="stats-small__label text-uppercase">{module}</span>
												<h6 className="stats-small__value count my-3">
													{model.name}
												</h6>
											</div>
											<div className="stats-small__data" style={{width: '100%', maxWidth: '100%', textAlign: 'center'}}>
												<span
													className="stats-small__percentage" style={{width: '100%', maxWidth: '100%', textAlign: 'center', paddingLeft: 0}}>
													Manage options
												</span>
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						)
					})}
				</Row>
			</Container>
		)
	}
}
export default ModuleList;
//http://localhost:8080/n-admin/list-modules

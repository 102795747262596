import React, {Fragment} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {Col, Row} from "shards-react";

const PageTitle = ({ title, subtitle, className, rightButton, ...attrs }) => {
	const classes = classNames(
		className,
		"text-sm-left",
		"mb-3",
		"text-center",
		"text-md-left",
		"mb-sm-0"
	);

	return (
		<Row>
			<Col xs="12" sm="4" className={classes} { ...attrs }>
				<span className="text-uppercase page-subtitle">{subtitle}</span>
				<h3 className="page-title">{title}</h3>
			</Col>
			{
				rightButton ?
					<Fragment>
						<Col xs="12" sm="4"></Col>
						<Col xs="12" sm="4" style={{textAlign: 'right', marginTop: '8px'}}>
							{rightButton}
						</Col>
					</Fragment>: null
			}
		</Row>
	)
};

PageTitle.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string
};

export default PageTitle;

import { combineReducers } from 'redux';

import user from './user';
import layout from './layout';
import moduleTables from './moduleTables';

export default combineReducers({
    user,
	layout,
	moduleTables
});

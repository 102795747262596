import types from '../types';
import { user as initial } from '../initial.json';

const user = (state = initial, action) => {
    switch (action.type) {
        case types.user.LOGOUT:
            return {...state, token: '', type: '', name: ''};
        case types.user.LOGIN:
            console.log(action.payload)
            return {...state, token: action.payload.token, userType: action.payload.type, typeId: action.payload.typeId};
        case types.user.REFRESH:
            return {...state, token: action.payload.token};
        default:
            return state
    }
}

export default user

import {http} from '../lib';

const getScore = async (scoreId, token) => {
    try {
        const result = await http.httpGet(`/scores/${scoreId}`,token,(err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
};

const saveScore = async (data, token) => {
    try {
        const result = await http.httpPost(`/scores`,data, token,(err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
};

const updateScore = async (scoreId, data, token) => {
    try {
        const result = await http.httpPatch(`/scores/${scoreId}`,data, token,(err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
};

const listScores = async (token) => {
    try {
        const result = await http.httpGet(`/scores`, token, (err) => {
            throw err;
        });
        return result;
    }catch(err){
        return err;
    }
}

export {
	getScore,
	saveScore,
	updateScore,
	listScores
}

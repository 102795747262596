import jwtDecode from 'jwt-decode';
import applicationConfig from "../config/application";
import {useDispatch, useSelector} from "react-redux";
import {account} from "../api";
import {actions} from "../store";

const validateToken = (token, allowedAudience, allowedIssuer) => {
    try {
        const decoded = jwtDecode(token);
        console.log('decoded',decoded)
        if ((!allowedAudience || decoded.aud === allowedAudience) && (!allowedIssuer || decoded.iss === allowedIssuer))
            return decoded;
    } catch (_) {
    }
    return false;
};

const authenticate = (dispatch) => {
    return async (username, password) => {
        const response = await account.login(username, password);
        if(response.jwt && response.user.roles.find(role => role === 'admin')){
            dispatch(actions.user.loginAction(response.jwt));
            return true;
        }
        return false
    }
}

function useJWT() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const {allowedAudience, allowedIssuer} = applicationConfig.JWT;
    console.log(token)
    const tokenInfo = validateToken(token, allowedAudience, allowedIssuer);
    let tokenState = {
        isAuthenticated: false,
        userName: '',
        userId: '',
    };
    if (tokenInfo)
        tokenState = {
            isAuthenticated: true,
            userName: tokenInfo.name,
            userId: tokenInfo.userId
        };
    return {
        tokenState,
        authenticate:  authenticate(dispatch)
    };
};

export default useJWT;

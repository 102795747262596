import types from '../types';
import { layout as initial } from '../initial.json';

const layout = (state = initial, action) => {
	switch (action.type) {
		case types.layout.TOGGLE_MENU_STATE:
			return {...state, menuState: !state.menuState};
		default:
			return state
	}
}

export default layout

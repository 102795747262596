import React from "react";
import PropTypes from "prop-types";
import {NavLink as RouteNavLink} from "react-router-dom";
import {NavItem, NavLink} from "shards-react";

const SidebarNavItem = ({item, hideLinkText}) => (
	<NavItem>
		<NavLink tag={RouteNavLink} to={item.to}>
			{item.htmlBefore && (
				<div
					className="d-inline-block item-icon-wrapper"
					dangerouslySetInnerHTML={{__html: item.htmlBefore}}
				/>
			)}
			{item.title && !hideLinkText && <span>{item.title}</span>}
			{item.htmlAfter && (
				<div
					className="d-inline-block item-icon-wrapper"
					dangerouslySetInnerHTML={{__html: item.htmlAfter}}
				/>
			)}
		</NavLink>
	</NavItem>
);

SidebarNavItem.propTypes = {
	/**
	 * The item object.
	 */
	item: PropTypes.object,
	hideLinkText: PropTypes.bool
};
SidebarNavItem.defaultProps = {
	hideLinkText: false
}

export default SidebarNavItem;

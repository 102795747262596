import { useState, useEffect } from 'react';
import {store} from "../store";
import {question} from "../api";

function useQuestions() {
    const [data, setData] = useState({questions: [], token: ''});

    useEffect(() => {
        if(!data.token){
            (async () => {
                    const {token} = store.getState().user;
                    if(token) {
                        const state = await question.listQuestions(token);
                        if(state.questions){
                            setData({questions: state.questions, token});
                        }
                    }
                }
            )();
        }
    });

    return {
		questions: data.questions
    };
}

export default useQuestions;

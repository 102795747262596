import React from "react";
import PropTypes from "prop-types";
import {Navbar, NavbarBrand} from "shards-react";
import {layout} from "../../../store/actions";
import {connect} from "react-redux";
import {ReactComponent as Logo} from "../../../images/notores-logo.svg";

class SidebarMainNavbar extends React.Component {
	static propTypes = {
		hideLogoText: PropTypes.bool
	};

	static defaultProps = {
		hideLogoText: false
	};

	render() {
		const {hideLogoText} = this.props;
		return (
			<div className="main-navbar">
				<Navbar
					className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
					type="light"
				>
					<NavbarBrand
						className="w-100 mr-0"
						href="#"
						style={{lineHeight: "25px"}}
					>
						<div className="d-table m-auto">
							<Logo
								id="main-logo"
								className="d-inline-block align-top mr-1"
								style={{maxWidth: "25px"}}
								alt="Shards Dashboard"
								width={40}
								height={40}
							/>
							{!hideLogoText && (
								<span className="d-none d-md-inline ml-1">
                  Notores
                </span>
							)}
						</div>
					</NavbarBrand>
					{/* eslint-disable-next-line */}
					<a
						className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
						onClick={this.props.dispatchers.toggleMenuState}
					>
						<i className="material-icons">&#xE5C4;</i>
					</a>
				</Navbar>
			</div>
		);
	}
}


const mapDispatchToProps = (dispatch) => ({
	dispatchers: {
		toggleMenuState: () => dispatch(layout.toggleMenuState())
	}
});

export default connect(
	null,
	mapDispatchToProps
)(SidebarMainNavbar);

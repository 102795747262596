import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import routes from './routes';
import withTracker from './withTracker';
import { DefaultLayout } from './layouts';
import {useAuthentication} from "./hooks";
import {Login} from "./components";

import 'bootstrap/dist/css/bootstrap.min.css';
import './shards-dashboard/scss/shards-dashboards.scss';
import './assets/notores-admin.css';

export default () => {
	const {tokenState} = useAuthentication();
	if(tokenState.isAuthenticated)
		return (
			<Router basename={'/n-admin'}>
				<div>
					{routes.map((route, index) => {
						return (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								component={withTracker(props => {
									return (
										<DefaultLayout {...props}>
											<route.component {...props} />
										</DefaultLayout>
									);
								})}
							/>
						);
					})}
				</div>
			</Router>
		);
	else
		return (
			<Login />
		);
};


export default function () {
	return [
		{
			title: "Dashboard",
			to: "/dashboard",
			htmlBefore: '<i class="material-icons">dashboard</i>',
			htmlAfter: ""
		},
		/*
		{
			title: "Modules",
			to: "/modules",
			htmlBefore: '<i class="material-icons">view_module</i>',
			htmlAfter: ""
		}*/
		{
			title: "Vragen",
			to: "/questions",
			htmlBefore: '<i class="material-icons">question_answer</i>',
			htmlAfter: ""
		},
		{
			title: "Score thema's",
			to: "/scores",
			htmlBefore: '<i class="material-icons">scatter_plot</i>',
			htmlAfter: ""
		}
	];
}

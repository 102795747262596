import React, {useState} from 'react';
import "./login.scss";
import {useAuthentication} from "../../hooks";
import {Button, Form, FormGroup, FormInput} from "shards-react";

export default () => {
    const {authenticate} = useAuthentication();
    const [state, setState] = useState({email: '', password: ''});
    const submit = async (e) => {
        console.log('subm', state);
        e.preventDefault();
        await authenticate(state.email, state.password);
    }
    return <div className="Login">
        <Form onSubmit={submit}>
            <h2 className="text-center">Log in</h2>
            <FormGroup>
                <FormInput type="text" className="form-control" placeholder="Username" required="required"
                             autoComplete="username" value={state.email} onChange={(e) => setState({...state, email: e.target.value})}/>
            </FormGroup>
            <FormGroup>
                <FormInput type="password" className="form-control" placeholder="Password" required="required"
                             autoComplete="password" value={state.password} onChange={(e) => setState({...state, password: e.target.value})}/>
            </FormGroup>
            <FormGroup>
                <Button variant="primary" type="submit" className="btn-block" onClick={submit}>Log in</Button>
            </FormGroup>
        </Form>
    </div>;
}

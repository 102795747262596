import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader} from "shards-react";
import Table from 'rc-table';

class DataTable extends Component {
	static propTypes = {
		columns: PropTypes.array,
		data: PropTypes.array,
		className: PropTypes.string,
		title: PropTypes.string
	}

	static defaultProps = {
		data: [],
		className: '',
		title: 'Data'
	}

	formatName = (camelCase) => {
		return camelCase
			.replace(/([a-z])([A-Z])/g, '$1 $2')
			.replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
			.toLowerCase()
			.replace(/^./, function (str) {
				return str.toUpperCase();
			});
	}

	getColumns = (columns) => {
		return columns.filter(col => col.active).map(col => ({
			title: this.formatName(col.name),
			dataIndex: col.name === 'id' ? '_id' : col.name,
			key: col.name,
		}))
	}

	render() {
		const {title, columns, data, className} = this.props;
		return (
			<Card small className="mb-4">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{title}</h6>
				</CardHeader>
				<CardBody className="p-0 pb-3">
					<Table className={`table mb-0 bg-light ${className}`} data={data} columns={this.getColumns(columns)} />
				</CardBody>
			</Card>
		)
	}

}

export default DataTable;

import React from "react";
import {Button, Col, Container, Row} from "shards-react";
import {withRouter, Link} from "react-router-dom";

import {PageTitle, TableCard} from "./../components";
import {useQuestions} from "../hooks";

const QuestionList = () => {
	const {questions} = useQuestions();
	return (
		<Container fluid className="main-content-container px-4">
			<Row noGutters className="page-header py-4">
				<Col>
					<PageTitle title="Vragen" subtitle="overzicht" rightButton={
						<Button theme="primary">
							<i className="material-icons">
								add_box
							</i>
							Toevoegen
						</Button>
					} />
				</Col>
			</Row>
			<Row>
				<Col>
					<TableCard title="Huidige vragen" columns={[
						{ title: 'Vraag', dataIndex: 'order', key: 'order', width: 100 },
						{ title: 'Titel', dataIndex: 'title', key: 'title' },
						{
							title: 'Acties',
							dataIndex: '',
							key: 'actions',
							render(record) {
								return <Link to={`/question/${record._id}`}>Bekijk</Link>;
							},
							width: 200
						}
					]} data={questions || []}/>
				</Col>
			</Row>
		</Container>
	)
}
export default withRouter(QuestionList);

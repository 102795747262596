let config = null;

export const init = (options) => {
    config = options;
}

export const httpRequest = async (path, token, method, content, onError) => {
    console.log('httpRequest', path, token, method)
    if(!config)
        throw new Error('http module needs to be initialized with options object containing an uri');

    try {
        const init = {
            method: method,
            headers: {
                'Accept': 'application/json',
            }
        };
        if(token) {
            init.headers['Authorization'] = `Bearer ${token}`;
            init.credentials = 'include';
        }
        if(content) {
            init.headers['Content-Type'] = 'application/json';
            init.body = JSON.stringify(content);
        }
        const request = await fetch(`${config.uri}${path}`, init);
        const result = await request.json();
        if(result.error && result.status && result.status === 401 && onError)
            onError(result);
        return result;
    } catch(error) {
        onError(error);
        return null;
    }
};

export const httpGet = async (path, token, onError) => {
    return await httpRequest(path, token, 'GET', null, onError);
};

export const httpPost = async (path, content, token, onError) => {
    return await httpRequest(path, token, 'POST', content, onError);
};

export const httpPut = async (path, content, token, onError) => {
    return await httpRequest(path, token, 'PUT', content, onError);
};

export const httpPatch = async (path, content, token, onError) => {
    return await httpRequest(path, token, 'PATCH', content, onError);
};

export const httpDelete = async (path, token, onError) => {
    return await httpRequest(path, token, 'DELETE', null, onError);
};
